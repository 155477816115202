import { Link } from "gatsby";
import React, { ComponentPropsWithRef, PropsWithChildren, ReactNode } from "react"
import { Box, Flex, NavLink } from "theme-ui";
import { Helmet } from "react-helmet";
import ActionButton from './ActionButton';
import CreateFirstEventButton from "./CreateFirstEventButton";
import Logo from "./Logo";

type LayoutProps = {
  children: ReactNode
}

export default ({ children, pageContext }: LayoutProps): JSX.Element => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
    }}>
    { pageContext?.frontmatter && <Helmet title={pageContext.frontmatter.title} /> }
    <Box as='header'
      sx={{
        width: '100%',
      }}>

      <Flex as="nav" sx={{
        maxWidth: 768,
        mx: 'auto',
      }}>
        <Flex sx={{
          justifyContent: 'space-between',
          width: '100%'
        }}>
          <Flex sx={{ alignItems: 'center' }}>
            <NavLink as={Link} to='/' sx={{ p: '2', display: 'flex', flexDirection: 'row', alignItems: 'center', fontSize: '2' }}>
              <Logo />&nbsp;Home
            </NavLink>
            <NavLink as={Link} to='/help' p={2}>
              Help
            </NavLink>
            <NavLink as={Link} to='/about' p={2}>
              About
            </NavLink>
          </Flex>
          <Box sx={{ display: 'flex', placeItems: 'center', px: '2' }}>
            <CreateFirstEventButton>Add my Event</CreateFirstEventButton>
          </Box>
        </Flex>
      </Flex>

    </Box>
    <Box as='main'
      sx={{
        width: '100%',
        flex: '1 1 auto',
      }}>
      <Box as='div'
        sx={{
          maxWidth: 768,
          mx: 'auto',
          px: 2,
        }}>
        {children}
      </Box>
    </Box>
    <Box as='footer'
      sx={{
        width: '100%',
        maxWidth: 768,
        mx: 'auto',
      }}>
      <Box as='div' sx={{
        maxWidth: 768,
        p: 2,
      }}>
        &copy; 2022 Conscious Events Community, provided by <a href="https://www.uber5.com">Uber5 Pty</a>.
      </Box>
    </Box>
  </Box>
)
