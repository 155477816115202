import React, {ReactNode} from "react";
import { Link } from "gatsby";
import { Link as ThemedLink } from "theme-ui";

type LinkInAppProps = {
  to: string,
  children: ReactNode
}

export default ({ to, children }: LinkInAppProps) => {
  return <ThemedLink as={Link} to={to}>{children}</ThemedLink>;
}
