
export type Event = {
  id: string,
  title: string,
  description?: string,
  date: string,
  start_time?: string,
  end_time?: string,
  created_at: Date,
  updated_at: Date,
  is_public: boolean,
  is_approved: boolean,
  image_url?: string,
  image_thumb_url?: string,
  image_card_url?: string,
  guid?: string
}

export function jsonToEvent(o: any): Event {
  return {
    id: '' + o.id,
    title: o.title,
    created_at: new Date(o.created_at),
    updated_at: new Date(o.updated_at),
    description: o.description,
    guid: o.guid,
    is_public: o.is_public ? true : false,
    is_approved: o.is_approved ? true : false,
    date: o.date,
    start_time: o.start_time ? o.start_time.match(/T(\d+:\d+)/)[1] : undefined,
    end_time: o.end_time ? o.end_time.match(/T(\d+:\d+)/)[1] : undefined,
    image_url: o.image_url,
    image_thumb_url: o.image_thumb_url,
    image_card_url: o.image_card_url
  }
}
