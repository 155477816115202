import React, {useCallback, useState} from "react";
import { Box, Card, Heading, Text } from "theme-ui";
import { Event } from '@mui/icons-material'
import ReactMarkdown from "react-markdown";
import { navigate } from "gatsby";
import ActionButton from "./ActionButton";
import ActionShareButton from "./ActionShareButton";
import linkUrl from "../lib/linkUrl";

export default ({ event }: { event: any }) => {

  return (
    <Card sx={{ pt: 2 }}>
      <Heading sx={{ fontSize: 2 }}>
	<Box sx={{ display: 'inline', pr: 2 }}>
	  <Event />
	</Box>
	{event.title}
      </Heading>
      <Text>
	<ReactMarkdown>
	  {event.description}
	</ReactMarkdown>
      </Text>
      <Box pt={2} pb={4}>
        <ActionShareButton
          title={event.title}
          text={`Invitation: ${event.title}`}
          url={`${linkUrl}/events/${event.guid}`}
          onClick={() => console.log("shared")} />
            &nbsp;
        <ActionButton isValid={true} onClick={() => navigate(`/owned-events/view/${event.id}`)}>
          See Details
        </ActionButton>
      </Box>
    </Card>
  );
}
