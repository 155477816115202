import React from "react";
import { RWebShare } from "react-web-share";
import ActionButton from "./ActionButton";

type EditButtonProps = {
  text: string,
  url: string,
  title: string,
  onClick: () => void
}

export default (props: EditButtonProps) => {
  const { text, url, title, onClick } = props;
  return (
    <RWebShare
      data={{
        text,
        url,
        title,
      }}
      onClick={props.onClick}
    >
      <ActionButton isValid={true} onClick={onClick}>
        Share
      </ActionButton>
    </RWebShare>
  );
}

