import { future } from '@theme-ui/presets'

const primary = "#514eb9";
const secondary = "#b5b94e";

export default {
  ...future,
  colors: {
    ...future.colors,
    hoverBackground: '#CDCCF9',
    primary,
    secondary,
    modes: {
      dark: {
        text: '#fff',
        background: '#000',
        hoverBackground: '#222',
        primary,
        secondary,
        highlight: '#f0c',
        muted: '#011',
      },
    },
  },
  styles: {
    ...future.styles,
  },
  images: {
    eventThumb: {
      maxWidth: 100,
      maxHeight: 100,
      width: 'auto',
      height: 'auto',
      borderRadius: 4
    },
    eventCard: {
      // maxHeight: 400,
      '@media screen and (min-width: 752px)': {
        borderRadius: 4,
        width: '752px',
      },
      '@media screen and (max-width: 751px)': {
        minWidth: '100vw',
        marginLeft: '-8px',
      },
    }
  },
  // cards: {
  //   primary: {
  //     padding: 2,
  //     borderRadius: 4,
  //     boxShadow: '0 0 8px rgba(0, 0, 0, 0.125)',
  //     backgroundColor: future.colors.secondary
  //   },
  // }
}
