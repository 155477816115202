import React from "react";
import { Box, Card, Flex, Heading, Image, Text } from "theme-ui";
import { Event as EventIcon } from '@mui/icons-material'
import ReactMarkdown from "react-markdown";
import AuthenticatedDataView from "./AuthenticatedDataView";
import { endpoint } from "../lib/endpoint";
import LinkInApp from "./LinkInApp";
import { Event, jsonToEvent } from '../types';
import {addDays, differenceInCalendarDays, format } from "date-fns";

type DayWithEvents = {
  day: string,
  daysFromNow: number,
  events: Event[]
}

function eventsToDays(events: Event[]): DayWithEvents[] {
  const days: DayWithEvents[] = [];
  const now = new Date();
  for (let daysFromNow = 0; daysFromNow < 29; daysFromNow++) {
    const day = format(addDays(now, daysFromNow), 'y-MM-dd');
    days.push({ day, events: [], daysFromNow });
  }
  for (const event of events) {
    const daysFromNow = differenceInCalendarDays(new Date(event.date), now);
    console.info("daysFromNow, event", daysFromNow, event);
    days[daysFromNow]?.events?.push(event);
  }
  return days;
}

type GroupWithDays = {
  hasEvents: boolean,
  days: DayWithEvents[],
}

const daysToGroups = (days: DayWithEvents[]): GroupWithDays[] => {
  const groups: GroupWithDays[] = [];
  let group: GroupWithDays | undefined = undefined;
  const pushPreviousGroupIfExists = () => {
    if (group) {
      groups.push(group);
      group = undefined;
    }
  };
  for (const day of days) {
    if (day.events.length > 0) {
      // as soon as there is at least one event, put the day into its own group
      pushPreviousGroupIfExists();
      groups.push({ hasEvents: true, days: [day] });
    } else {
      // use the existing group
      if (!group) {
        group = { days: [], hasEvents: false }
      }
      group.days.push(day);
    }
  }
  return groups;
}

const EventView = ({ event, when }: { event: Event, when: string }) => {
  return (
    <Card py={2}>
      <Flex sx={{ justifyContent: "space-between" }} >
        <Box>
          <Heading>
            <Box sx={{ display: 'inline', pr: 2 }}>
              <EventIcon />
            </Box>
            <LinkInApp to={`/events/${event.guid}`}>
              {event.title}
            </LinkInApp>
          </Heading>
          <Text>When: {when} at {event.start_time}</Text>
          { false && event.description &&
            <Text>
              <ReactMarkdown>
                {event.description || '(no description)'}
              </ReactMarkdown>
            </Text>
          }
        </Box>
        { event.image_thumb_url &&
          <Box>
            <Image src={event.image_thumb_url} variant="eventThumb" />
          </Box>
        }
      </Flex>
    </Card>
  );
}

const EventsOnADay = ({ day }: { day: DayWithEvents }) => {
  const { daysFromNow, events } = day;
  // const now = new Date();
  // const today = format(now, 'y-MM-dd');
  console.info("day", day);
  const when = daysFromNow == 0 ? 'Today' : daysFromNow == 1 ? 'Tomorrow' :
    daysFromNow < 7 ? `Next ${format(new Date(day.day), 'eeee (dd MMMM)')}` : format(new
        Date(day.day), 'PPP');

  if (day.events.length == 0) {
    return <>
      <Box>No events {when}</Box>
      <hr />
    </>
  }
  return (<>
      {/*<Text>{day.events.length} events {when}:</Text>*/}
    { events.map(event => (
      <Box key={event.guid}>
        <EventView event={event} when={when} />
        <hr/>
      </Box>
    )) }
  </>);
}

const PublicEventsGroupView = ({ group }: { group: GroupWithDays }) => {
  const lastDay = group.days[group.days.length - 1];
  const when = lastDay.daysFromNow == 0 ? 'today' : lastDay.daysFromNow == 1 ? 'tomorrow' :
    lastDay.daysFromNow < 7 ? `next ${format(new Date(lastDay.day), 'eeee')}` : format(new
        Date(lastDay.day), 'dd MMMM');

  if (group.hasEvents) {
    return <EventsOnADay day={group.days[0]} />
  } else {
    return <Box>No events until {when}</Box>
  }
}

const PublicEventsView = ({ events }: { events: Event[] }) => {
  
  console.info("events", events);
  const days = eventsToDays(events);
  console.info("days", days);
  const groups = daysToGroups(days);
  return <>
  { groups.map(group => (
    <PublicEventsGroupView key={group.days[0].day} group={group} />
  )) }
  </>
}

export default () => {
  return (
    <AuthenticatedDataView fetchUrl={`${endpoint}/events`}
      render={data => (
        <>
          <PublicEventsView events={data.events.map(jsonToEvent)} />
        </>
      )}
    />
  );
}

