import React from "react";
import { StaticImage } from "gatsby-plugin-image";

export default () => (
  <StaticImage src="../images/header-logo.png" alt="header logo"
    loading="eager"
    width={48}
    quality={95}
    formats={["auto", "webp", "avif"]}
  />
);
