import React, { ReactNode } from "react";
import { Button } from "theme-ui";
import theme from "../theme";

type ActionButtonProps = {
  onClick?: () => void,
  isLoading?: boolean,
  isSubmitting?: boolean,
  isValid?: boolean,
  type?: 'button' | 'reset' | 'submit',
  children: ReactNode
}

export default ({ children, onClick, isLoading, isSubmitting, isValid, type }:
ActionButtonProps) => {

  return (
    <Button
      sx={{
        color: theme.colors.background,
        bg: theme.colors.primary,
        "&:hover": {
          cursor: 'pointer',
          bg: theme.colors.hoverBackground,
          color: theme.colors.primary,
          "&:disabled": { bg: theme.colors.muted },
        },
      }}
      disabled={isLoading || isSubmitting || !isValid}
      type={type || "submit"}
      onClick={onClick}
    >
      {children}
    </Button>
  );
}
